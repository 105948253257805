import React, { useEffect, useState } from 'react';
import './App.css';
import TextoLetreado from './components/TextoLetreado/TextoLetreado';

function App() {
  const BASE_URL = "https://alanvillar.com/api";

  const [data, setData] = useState([]);
  const [showSectionCero, setShowSectionCero] = useState(true);
  const [showSectionOne, setShowSectionOne] = useState(false);
  const [startTyping, setStartTyping] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}/inicio-alan-villars`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setData(data);
      })
      .catch(error => console.error(error));

    // Detectar si es un dispositivo móvil
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    const timeout = setTimeout(() => {
      setShowSectionCero(false);
      setShowSectionOne(true);
      document.body.style.overflow = 'hidden';
    }, 5000);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', checkIfMobile);
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleAcercaDeMiClick = () => {
    setStartTyping(true);
  };

  return (
    <>
      {showSectionCero && (
        <section id="cero" className="spotlight bottom">
          {Array(100).fill(0).map((_, i) => (
            <div key={i} className='star'></div>
          ))}
          <div className='welcome'>
            <h1>{data.length > 0 ? data[0].Bienvenida : "Cargando..."}</h1>
            <div className="loader"></div>
          </div>
        </section>
      )}
      {showSectionOne && (
        <section id='one' className='spotlight bottom'>
          {Array(100).fill(0).map((_, i) => (
            <div key={i} className='star'></div>
          ))}
          {data.map((item, index) => (
            <div className='container' key={index}>
              <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-4'>
                  <div className='menu-container'>
                    <a
                      className='ithem-menu'
                      href="#two"
                      target='_self'
                      onClick={handleAcercaDeMiClick}
                    >
                      Acerca de mí
                    </a>
                    <a
                      className='ithem-menu'
                      href={`${BASE_URL}${item?.CV?.url}`}
                      target='_blank'
                      download
                    >
                      Descargar CV
                    </a>
                    <a
                      className='ithem-menu'
                      href="https://wa.me/527711030518?text=Hola,%20he%20visto%20tu%20sitio%20web%20y%20me%20gustaría%20ponerme%20en%20contacto%20contigo."
                      target='_blank'
                      rel="noopener noreferrer"
                    >
                      Ponte en contacto conmigo
                    </a>
                  </div>
                </div>
                <div className='col-md-4'></div>
              </div>
            </div>
          ))}
        </section>
      )}
      {startTyping && (
        <section 
          id='two' 
          className='spotlight bottom' 
          style={isMobile ? { overflowY: 'auto', maxHeight: '100vh' } : {}}
        >
          {Array(100).fill(0).map((_, i) => (
            <div key={i} className='star'></div>
          ))}
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                {data.length > 0 && (
                  <TextoLetreado
                    parrafos={data[0]?.textparrafo || [{ parrafo: "Cargando..." }]}
                    start={startTyping}
                  />
                )}
                <div className='container-back-menu'>
                  <a className='back-menu' href='#one'>Regresar</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default App;
